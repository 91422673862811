import React from 'react';
import PropTypes from 'prop-types';
import reactMixin from 'react-mixin';
import mixins from '../mixins';
import { Slide } from "react-slideshow-image/"
import 'react-slideshow-image/dist/styles.css'

const slideImages = [
  require('../../img/cartes/banikoara.png'),
  require('../../img/cartes/kalale.png'),
  require('../../img/cartes/karimama.png'),
  require('../../img/cartes/ndali.png'),
  require('../../img/cartes/gogounou.png'),
  require('../../img/cartes/tchaourou.png'),
  require('../../img/cartes/ouesse.png'),
  require('../../img/cartes/benin.png'),
];

class GeoPortal extends React.Component {
    constructor(props) {
        super(props); 
    }
    render () {
        return (
            <div className="geoportal">
                <div className="geo-header">
                    <span className="geo-logo"/>
                    <h1 className="title">Visualisation géographique et graphique
                    des indicateurs de performences</h1>
                </div>
                <div className="geo-content">
                    <table>
                        <tr>
                            <td>
                            <h2>Nos communes d'intervention</h2>
                            <ul>
                                <li>
                                <h3>ALIBORI</h3>
                                Karimama, Banikoara, Gogounou, ...
                                </li>
                                <li>
                                <h3>BORGOU</h3>
                                Kalalé, N'dali, Tchaourou
                                </li>
                                <li><h3>COLLINES</h3>
                                Ouessè
                                </li>
                            </ul>
                            </td>
                            <td>
                                <span className="benin-carte"/>
                            </td>
                        </tr>
                    </table>
                    <div className="slide-container">
                    <Slide duration={10000000}>
                    {slideImages.map((slideImage, index)=> (
                        <div className="each-slide" key={index}>
                            <div className="image-container">
                            <img src={slideImage} width="150px" />
                            </div>
                        </div>
                        ))} 
                    </Slide>
                    </div>
                </div>
            </div>
        );
    }
}

reactMixin(GeoPortal.prototype, mixins.contextRouter);
reactMixin(GeoPortal.prototype, mixins.permissions);

GeoPortal.contextTypes = {router: PropTypes.object};

export default GeoPortal;
