import React from 'react';
import PropTypes from 'prop-types';
import reactMixin from 'react-mixin';
import mixins from '../mixins';
import { Link } from 'react-router';
import autoBind from 'react-autobind';
import {ROUTES} from '../constants';
import {actions} from '../actions'
import { hashHistory } from 'react-router';
import {bem} from '../bem';

class MainButton extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }
    onClick (evt) {
        actions.auth.verifyLogin.triggerAsync().then(() => {
            hashHistory.push(this.props.linkto);
        });
    }
    render () {
      var icon_class = (this.props['ki-icon'] == undefined ? 'fa fa-globe' : `k-icon-${this.props['ki-icon']}`);
      var icon = (<i className={icon_class}/>);
      var classNames = [this.props.class, 'k-drawer__link'];
  
      var link;
      if (this.props.linkto) {
        link = (
        //   <Link to={this.props.linkto}
        //       className={classNames.join(' ')}
        //       activeClassName='active'
        //       data-tip={this.props.label}>
        //     {icon}
        //   </Link>
        <bem.KoboButton onClick={this.onClick} m={['blue']} className="button">
            {this.props.text}
        </bem.KoboButton>
        );
      } else {
        link = (
          <a href={this.props.href || '#'}
              className={classNames.join(' ')}
              onClick={this.onClick}
              data-tip={this.props.label}>
              {icon}
          </a>
        );
      }
      return link;
    }
  }

class MainMenu extends React.Component {
    constructor(props) {
        super(props); 
    }
    render () {
        return (
            <div className="main-menu">
                <table>
                    <tr>
                    <td>
                        <MainButton label={t('Projects')} linkto={ROUTES.FORMS} ki-icon='projects' text="FORMULAIRES"/>
                    </td>
                    <td>
                        <MainButton label={t('Library')} linkto={ROUTES.LIBRARY} ki-icon='library' text="BIBLIOTHEQUE"/>
                    </td>
                    </tr>
                </table>
            </div>
        );
    }
}

reactMixin(MainMenu.prototype, mixins.contextRouter);
reactMixin(MainMenu.prototype, mixins.permissions);

MainMenu.contextTypes = {router: PropTypes.object};

export default MainMenu;
