import React from 'react';
import autoBind from 'react-autobind';
import zxcvbn from 'zxcvbn';
import {bem} from '../bem';

/*
Properties:
- password <string>: required
*/
class PasswordStrength extends React.Component {
  constructor(props){
    super(props);
    autoBind(this);
    this.feedbackTranslations = {
      // strings for zxcvbn 4.4.2 package
      // copied from https://github.com/dropbox/zxcvbn/blob/master/src/feedback.coffee
      // not the most concise approach, but hopefully the most intuitive; could
      // be removed if https://github.com/dropbox/zxcvbn/pull/124 is ever merged
      "Utilisez quelques mots, évitez les phrases courantes":
        t ('Utilisez quelques mots, évitez les phrases courantes'),
      'Pas besoin de symboles, de chiffres ou de lettres majuscules':
        t ('Pas besoin de symboles, de chiffres ou de lettres majuscules'),
      'Ajoutez un autre mot ou deux. Les mots peu courants sont meilleurs. ':
        t ("Ajoutez un ou deux mots supplémentaires. Les mots peu courants sont meilleurs."),
      'Les rangées droites de clés sont faciles à deviner':
        t ('Les lignes droites de clés sont faciles à deviner'),
      'Les modèles de clavier courts sont faciles à deviner':
        t ('Les motifs de clavier courts sont faciles à deviner'),
      'Utilisez un motif de clavier plus long avec plus de tours':
        t ('Utiliser un motif de clavier plus long avec plus de tours'),
      'Les répétitions comme "aaa" sont faciles à deviner ':
        t ('Les répétitions comme "aaa" sont faciles à deviner'),
      'Les répétitions comme "abcabcabc" ne sont que légèrement plus difficiles à deviner que "abc"':
        t ('Les répétitions comme "abcabcabc" ne sont que légèrement plus difficiles à deviner que "abc"'),
      'Évitez les mots et les caractères répétés':
        t ('Évitez les mots et les caractères répétés'),
      'Les séquences comme abc ou 6543 sont faciles à deviner':
        t ('Les séquences comme abc ou 6543 sont faciles à deviner'),
      'Évitez les séquences':
      t ('Eviter les séquences'),
      'Les dernières années sont faciles à deviner':
        t ('Les dernières années sont faciles à deviner'),
      'Évitez les dernières années':
        t ('Eviter les dernières années'),
      'Évitez les années qui vous sont associées':
        t ('Évitez les années qui vous sont associées'),
      'Les dates sont souvent faciles à deviner':
        t ('Les dates sont souvent faciles à deviner'),
      'Évitez les dates et les années qui vous sont associées':
        t ('Évitez les dates et les années qui vous sont associées'),
      'Ceci est un mot de passe commun parmi les 10 principaux':
        t ('Ceci est un mot de passe commun parmi les 10 premiers'),
      "Ceci est un mot de passe commun parmi les 100 premiers":
        t ('Ceci est un mot de passe commun parmi les 100 premiers'),
      'Ceci est un mot de passe très courant':
        t ('Ceci est un mot de passe très courant'),
      'Ceci est similaire à un mot de passe couramment utilisé':
        t ('Ceci est similaire à un mot de passe couramment utilisé'),
      'Un mot en lui-même est facile à deviner':
        t ('Un mot en lui-même est facile à deviner'),
        'Les noms et prénoms sont faciles à deviner':
        t ('Les noms et prénoms seuls sont faciles à deviner'),
      'Les noms et prénoms communs sont faciles à deviner':
        t ('Les noms et prénoms communs sont faciles à deviner'),
      "La capitalisation n'aide pas beaucoup":
        t ("La capitalisation n'aide pas beaucoup"),
      'Tout en majuscules est presque aussi facile à deviner que tout en minuscules':
        t ('Tout en majuscules est presque aussi facile à deviner que tout en minuscules'),
      "Les mots inversés ne sont pas beaucoup plus difficiles à deviner":
        t ("Les mots inversés ne sont pas beaucoup plus difficiles à deviner"),
      "Les substitutions prévisibles comme '@' au lieu de 'a' n'aident pas beaucoup":
        t ("Les substitutions prévisibles comme '@' au lieu de 'a' n'aident pas beaucoup")
    };
  }

  render() {
    const report = zxcvbn(this.props.password);
    const barModifier = `score-${report.score}`;
    return (
      <bem.PasswordStrength>
        <bem.PasswordStrength__title>
          {t('Fiabilité du mot de passe')}
        </bem.PasswordStrength__title>

        <bem.PasswordStrength__bar m={barModifier}>
          <bem.PasswordStrength__indicator/>
        </bem.PasswordStrength__bar>

        {(report.feedback.warning || report.feedback.suggestions.length > 0) &&
          <bem.PasswordStrength__messages>
            {report.feedback.warning &&
              <bem.PasswordStrength__message m='warning'>
                {this.feedbackTranslations[report.feedback.warning]}
              </bem.PasswordStrength__message>
            }

            {report.feedback.suggestions.length > 0 &&
              report.feedback.suggestions.map((suggestion, index) => {
                return (
                  <bem.PasswordStrength__message key={index}>
                    {this.feedbackTranslations[suggestion]}
                  </bem.PasswordStrength__message>
                )
              })
            }
          </bem.PasswordStrength__messages>
        }
      </bem.PasswordStrength>
    )
  }
}

export default PasswordStrength;
