import React from 'react';
import Reflux from 'reflux';
import reactMixin from 'react-mixin';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {bem} from 'js/bem';
import myLibraryStore from './myLibraryStore';
import {actions} from '../../actions';
import { hashHistory } from 'react-router';

class MainButton extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  onClick (evt) {
      actions.auth.verifyLogin.triggerAsync().then(() => {
          hashHistory.push(this.props.linkto);
      });
  }
  render () {
    var icon_class = (this.props['ki-icon'] == undefined ? 'fa fa-globe' : `k-icon-${this.props['ki-icon']}`);
    var icon = (<i className={icon_class}/>);
    var classNames = [this.props.class, 'k-drawer__link'];

    var link;
    if (this.props.linkto) {
      link = (
      //   <Link to={this.props.linkto}
      //       className={classNames.join(' ')}
      //       activeClassName='active'
      //       data-tip={this.props.label}>
      //     {icon}
      //   </Link>
      <bem.KoboButton onClick={this.onClick} m={['fullwidth']}>
          {this.props.text}
          { this.props.showCount &&
            <bem.FormSidebar__labelCount>{}{this.props.count}</bem.FormSidebar__labelCount>
          }
      </bem.KoboButton>
      );
    } else {
      link = (
        <a href={this.props.href || '#'}
            className={classNames.join(' ')}
            onClick={this.onClick}
            data-tip={this.props.label}>
            {icon}
        </a>
      );
    }
    return link;
  }
}


class LibrarySidebar extends Reflux.Component {
  constructor(props){
    super(props);
    this.state = {
      myLibraryCount: 0,
      isLoading: true
    };
    autoBind(this);
  }

  componentDidMount() {
    this.listenTo(myLibraryStore, this.myLibraryStoreChanged);
    this.setState({
      isLoading: false,
      myLibraryCount: myLibraryStore.getCurrentUserTotalAssets()
    });
  }

  myLibraryStoreChanged() {
    this.setState({
      isLoading: false,
      myLibraryCount: myLibraryStore.getCurrentUserTotalAssets()
    });
  }

  isMyLibrarySelected() {
    return this.context.router.isActive('library/my-library');
  }

  isPublicCollectionsSelected() {
    return this.context.router.isActive('library/public-collections');
  }

  render() {
    let sidebarModifier = '';
    if (this.state.isLoading) {
      sidebarModifier = 'loading';
    }

    return (
      <React.Fragment>
        <bem.Header__logo />
        <bem.Header__ourLogo />
        <bem.FormSidebar m={sidebarModifier}>
          <MainButton
            //m={{selected: this.isMyLibrarySelected()}}
            linkto='/library/my-library'
            text='MA BIBLIOTHÈQUE'
            count={this.state.myLibraryCount}
            showCount
          >
            {/* <i className='k-icon-library'/> */}
            {/* <bem.FormSidebar__labelText>{t('My Library')}</bem.FormSidebar__labelText> */}
          </MainButton>

          <MainButton
            // m={{selected: this.isPublicCollectionsSelected()}}
            linkto='/library/public-collections'
            text='COLLECTION PUBLIQUE'
          />
            {/* <i className='k-icon-library-public'/> */}
            {/* <bem.FormSidebar__labelText>{t('Public Collections')}</bem.FormSidebar__labelText> */}
        </bem.FormSidebar>
      </React.Fragment>
    );
  }
}

LibrarySidebar.contextTypes = {
  router: PropTypes.object
};

reactMixin(LibrarySidebar.prototype, Reflux.ListenerMixin);

export default LibrarySidebar;
