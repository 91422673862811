import React from 'react';
import PropTypes from 'prop-types';
import reactMixin from 'react-mixin';
import mixins from '../mixins';
import autoBind from 'react-autobind';
import {ROUTES} from '../constants';
import {actions} from '../actions'
import { hashHistory } from 'react-router';
import {bem} from '../bem';

class MainButton extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }
    onClick (evt) {
        actions.auth.verifyLogin.triggerAsync().then(() => {
            hashHistory.push(this.props.linkto);
        });
    }
    render () {
      var icon_class = (this.props['ki-icon'] == undefined ? 'fa fa-globe' : `k-icon-${this.props['ki-icon']}`);
      var icon = (<i className={icon_class}/>);
      var classNames = [this.props.class, 'button'];

      var link;
      if (this.props.linkto) {
        link = (
        //   <Link to={this.props.linkto}
        //       className={classNames.join(' ')}
        //       activeClassName='active'
        //       data-tip={this.props.label}>
        //     {icon}
        //   </Link>
        <bem.KoboButton onClick={this.onClick} className="button button--orange">
            {this.props.text}
        </bem.KoboButton>
        );
      } else {
        link = (
            <a href={this.props.href || '#'}
                target='_blank'>
                <bem.KoboButton className={"button button--"+this.props.modifier}>
                    {this.props.text}
                </bem.KoboButton>
            </a>
        );
      }
      return link;
    }
  }

class SystemMenu extends React.Component {
    constructor(props) {
        super(props);
    }
    render () {
        return (
            <div className="main-menu">
                <table>
                    <tr>
                    <td>
                        <MainButton label={t('Gérer la collecte')} linkto={ROUTES.MENU} ki-icon='projects' text="COLLECTE DE DONNEES"/>
                    </td>
                    <td>
                        <MainButton label={t('Gérer la BDD')} modifier="green" href='https://pgadmin4.2mkouas.com/' ki-icon='library' text="GERER LA BDD"/>
                    </td>
                    <td>
                        <MainButton label={t('Visualiser les données')} modifier="blue" ki-icon='library' href="https://dash.2mkouas.com/" text="GEOPORTAIL"/>
                    </td>
                    </tr>
                </table>
            </div>
        );
    }
}

reactMixin(SystemMenu.prototype, mixins.contextRouter);
reactMixin(SystemMenu.prototype, mixins.permissions);

SystemMenu.contextTypes = {router: PropTypes.object};

export default SystemMenu;
